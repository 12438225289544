
import Vue from "vue";
import Footer from "@/components/Footer.vue";
import SignUpMentor from "@/components/SignUp/SignUpMentor.vue";
import HeaderTwo from "@/components/HeaderTwo.vue";

export default Vue.extend({
  name: "SignUpPageMentor",
  components: {
    HeaderTwo,
    Footer,
    SignUpMentor
  }
});
